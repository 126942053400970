.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  height: fit-content;
  width: 600px;
  background: white;
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
}

.main h3 {
  font-size: 24px;
  padding-bottom: 20px;
  height: 50px;
}

.main h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main h3 svg {
  height: 30px;
}

.main ul {
  list-style: none;
  height: 400px;
  overflow: auto;
}

.main ul li {
  border-bottom: 1px solid lightgrey;
  padding: 10px 15px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
}

.main ul li p {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  overflow-y: auto;
}

.main button {
  color: rgba(246, 84, 14, 1);
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .main {
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    padding: 20px 15px;
    overflow: hidden;
  }

  .main ul {
    list-style: none;
    height: calc(100vh - 100px);
    overflow: auto;
  }
}
