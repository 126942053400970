.input_ {
  -webkit-appearance: none; /* Fix for Safari */
  appearance: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.input_:hover {
  border-color: transparent !important;
  background-color: #f8f8f8;
}

.input_:focus {
  outline: none;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: #f8f8f8;
}

.socialIcon {
  transition: all 0.3s ease;
}

.socialIcon:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.footerLink {
  transition: color 0.3s ease;
}

.footerLink:hover {
  color: #F6540E;
}
