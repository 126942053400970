.page_container {
  height: 100vh;
  padding-top: 70px;
}

.message_container {
  height: 100%;
  width: 100%;
  padding-inline: 12.54vw;
  padding-top: 20px;
  padding-bottom: 20px;
}

.message_box {
  width: 100%;
  height: 100%;
  border: 2px solid #e2e8f0;
  border-radius: 30px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 35% 1fr;
}

.user_list {
  width: 100%;
  height: 100%;
  border-right: 2px solid #e2e8f0;
  display: flex;
  flex-direction: column;
}

.user_list_heading_part {
  height: fit-content;
  flex-shrink: 0;
  padding: 15px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 2px solid darkgrey;
}

.user_search_container {
  height: 45px;
  background: #f0f0f0;
  width: 100%;
  border-radius: 15px;
}

.user_tabs {
  height: 50px;
  width: 100%;
  border: 2px solid #e2e8f0;
  border-radius: 15px;
  margin-top: 10px;
}

.user_list_heading_part h1 {
  font-size: 3rem;
  font-family: Gilroy-Bold;
}

.users {
  flex-grow: 1;
}

.chat_box {
  width: 100%;
  height: 100%;
}
