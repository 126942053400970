.container {
  padding-top: 12rem;
  padding-inline: 15rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 50px;
}

.section_head {
  display: flex;
  justify-content: space-between;
}

.section_head h2 {
  font-size: 3rem;
  color: #000;
  font-family: "Gilroy-Bold";
}

.section_head button {
  background: var(--primary-color);
  color: var(--text-secondary-color);
  border-radius: 1.5rem;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  height: fit-content;
}

.main {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 1.5rem;
  height: fit-content;
}

.main_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.tabs_container {
  display: flex;
  list-style: none;
  border: 1px solid var(--border-color);
  width: fit-content;
  gap: 10px;
  padding: 0.5rem;
  border-radius: calc(1.5rem + 0.5rem);
}

.tabs_container li {
  font-size: 1.2rem;
  color: var(--text-tertiary-color);
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  border: 1px solid transparent;
  cursor: pointer;
}

.tabs_container li:hover {
  border: 1px solid var(--primary-color-opacity-1);
}

.tabs_container li.active {
  background: var(--primary-color-opacity-1);
  color: var(--primary-color);
}

.main_search_container {
  display: flex;
  align-items: center;
  background: var(--tertiary-color);
  height: fit-content;
  gap: 5px;
  padding: 1rem 1rem;
  width: 300px;
  border-radius: 1.5rem;
}

.main_search_container input {
  background-color: transparent;
  font-size: 1.3rem;
}

.main_search_container input:focus {
  outline: none;
}

.main_body {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  min-height: 500px;
  overflow: auto;
}

.main_body table {
  width: 100%;
}

.main_body table tr {
  border-bottom: 1px solid var(--border-color);
}

.main_body table th,
.main_body table td {
  text-align: left;
  font-size: 1.2rem;
  padding: 1.3rem 1rem 1.3rem 1rem;
  white-space: nowrap;
}

.main_body tbody {
  width: 100%;
  overflow: scroll;
}

.main_body tbody tr:hover td {
  background: var(--tertiary-color);
}

.in_progress {
  background: rgba(247, 215, 22, 0.2);
  padding: 1rem;
  border-radius: 1.3rem;
}

.searching_talent {
  padding: 1rem;
  border-radius: 1.3rem;
  background: rgba(246, 84, 14, 0.1);
  color: rgba(246, 84, 14, 1);
}

.action_btns {
  display: flex;
  gap: 10px;
}

.action_btns svg {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.action_btns svg:hover path {
  fill: #2b2b2b;
}

.action_btns svg path {
  fill: #a6a6a6;
}

.main_footer {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: flex;
  list-style: none;
  gap: 10px;
}

.pagination li {
  height: 2rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
}

.pagination li.active {
  background: var(--primary-color);
  color: var(--text-secondary-color);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_card {
  height: fit-content;
  width: 40%;
  background: #fff;
  border-radius: 1.5rem;
}

.modal_head {
  padding: 1rem 1.5rem;
  font-size: 2rem;
  font-family: "Gilroy-Bold";
  border-bottom: 1px solid var(--border-color);
}

.modal_content {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  font-family: "Gilroy-Medium";
}

.modal_footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid var(--border-color);
}

.modal_footer button {
  border-radius: 1.5rem;
  padding: 0.9rem 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  height: fit-content;
}

.modal_footer button:first-child {
  background: var(--tertiary-color);
}

.modal_footer button:last-child {
  background: rgb(255, 41, 41);
  color: white;
}

@media only screen and (max-width: 700px) {
  .container {
    padding-top: 10rem;
    padding-inline: 3vw;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 50px;
  }

  .main {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 1.5rem;
    height: fit-content;
    overflow: hidden;
  }

  .main_head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    gap: 10px;
    border-bottom: 1px solid var(--border-color);
  }

  .tabs_container {
    display: flex;
    list-style: none;
    border: 1px solid var(--border-color);
    width: 100%;
    gap: 10px;
    padding: 0.5rem;
    border-radius: 12px;
  }

  .tabs_container li {
    font-size: 1rem;
    color: var(--text-tertiary-color);
    padding: 1rem 1.5rem;
    border-radius: 1.5rem;
    border: 1px solid transparent;
    cursor: pointer;
  }

  .tabs_container li:hover {
    border: 1px solid var(--primary-color-opacity-1);
  }

  .tabs_container li.active {
    background: var(--primary-color-opacity-1);
    color: var(--primary-color);
  }

  .main_search_container {
    display: flex;
    align-items: center;
    background: var(--tertiary-color);
    height: fit-content;
    gap: 5px;
    padding: 1rem 1rem;
    width: 100%;
    border-radius: 12px;
  }

  .main_search_container input {
    background-color: transparent;
    font-size: 1.3rem;
  }

  .main_search_container input:focus {
    outline: none;
  }
}
