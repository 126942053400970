.talents-card-1,
.talents-card-2,
.talents-card-3,
.talents-card-4,
.talents-card-5,
.talents-card-6,
.talents-card-8,
.talents-card-9,
.talents-card-10,
.talents-card-11,
.talents-card-12 {
	background-size: cover;
	background-position: 50% 50%;
}

.talents-card-1 {
	background-image: url("../../../../Assets/Images/cards/Rectangle\ 7.png");
}

.talents-card-2 {
	background-image: url("../../../../Assets/Images/cards/Rectangle\ 8.png");
}

.talents-card-3 {
	background-image: url("../../../../Assets/Images/cards/Rectangle\ 9.png");
}

.talents-card-4 {
	background-image: url("../../../../Assets/Images/cards/Rectangle\ 10.png");
}

.talents-card-5 {
	background-image: url("../../../../Assets/Images/cards/Bassist.jpg");
}
.talents-card-6 {
	background-image: url("../../../../Assets/Images/cards/Drummer.jpg");
}
.talents-card-7 {
	background-image: url("../../../../Assets/Images/cards/Flutist.jpg");
}
.talents-card-8 {
	background-image: url("../../../../Assets/Images/cards/Guitarist.jpg");
}
.talents-card-9 {
	background-image: url("../../../../Assets/Images/cards/Pianist.jpg");
}
.talents-card-10 {
	background-image: url("../../../../Assets/Images/cards/Sound\ Engineer.jpg");
}
.talents-card-11 {
	background-image: url("../../../../Assets/Images/cards/Tabla\ Player.jpg");
}
.talents-card-12 {
	background-image: url("../../../../Assets/Images/cards/Violinist.jpg");
}
