.icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-20%);
  width: 1.25vw;
  height: 1.25vw;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .icon {
    top: 60%;
    right: -15px;
    width: 20px;
    height: 20px;
  }
}
