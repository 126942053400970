.button {
  height: 55px;
  width: 100%;
  border-radius: 15px;
  font-size: 1.1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: "Gilroy-SemiBold";
}

.button svg {
  height: 15px;
}

.outlined {
  border: 2px solid #f6540e;
  color: white;
  transition: 0.5s;
}

.outlined:hover {
  background: #f6540e;
}

.filled {
  background-color: #f6540e;
  color: white;
  transition: 0.5s;
}

.filled:hover {
  background-color: #a53f14;
}
