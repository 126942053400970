.icons {
  height: 2rem;
  width: 2rem;
}

.text {
  display: none;
}

.text_2 {
  display: block;
}

.share_icon {
  width: 1.45vw;
  height: 1.45vw;
}

@media only screen and (max-width: 768px) {
  .icons {
    height: 5rem;
    width: 5rem;
  }

  .text {
    display: block;
  }
  .text_2 {
    display: none;
  }
  .share_icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}
