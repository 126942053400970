.msg_icon {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}

.logo {
  height: auto;
  width: 150px;
}

@media only screen and (max-width: 768px) {
  .msg_icon {
    height: 1.7rem;
    width: auto;
  }
}
