.icons {
  fill: #f6540e;
  width: 4rem;
  height: 4rem;
  marginbottom: 1.8vh;
}

@media only screen and (max-width: 768px) {
  .icons {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1rem;
  }
}
