.icon {
  width: 1.25vw;
  height: 1.25vw;
}

.social_media_icon {
  height: 3rem;
  width: 3rem;
  border: 2px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.social_media_icon svg {
  fill: #f6540e;
  height: 1.5rem;
  width: auto;
  transition: 0.5s;
}

.social_media_icon:hover {
  background: #f6540e;
  border: 2px solid #f6540e;
}

.social_media_icon:hover svg {
  fill: white;
}

.social_media_icon:active {
  outline: none;
}

.social_media_icon:active svg {
  outline: none;
}

.social_media_icon svg:active {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .icon {
    width: 5vw;
    height: 5vw;
  }
}
