@font-face {
  font-family: "Gilroy-Black";
  src: url("../Assets/Fonts/Gilroy/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../Assets/Fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("../Assets/Fonts/Gilroy/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Heavy";
  src: url("../Assets/Fonts/Gilroy/Gilroy-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("../Assets/Fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("../Assets/Fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("../Assets/Fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../Assets/Fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Thin";
  src: url("../Assets/Fonts/Gilroy/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-UltraLight";
  src: url("../Assets/Fonts/Gilroy/Gilroy-UltraLight.ttf") format("truetype");
}

:root {
  --primary-color: rgb(246, 84, 14);
  --primary-color-opacity-1: rgba(246, 84, 14, 0.1);
  --secondar-color: #f6540e;
  --tertiary-color: #e5e5e5;

  --text-primary-color: black;
  --text-secondary-color: white;
  --text-tertiary-color: #8c8c8c;

  --border-color: #e5e5e5;
}

/* hide scroll bar */

.hide-it::-webkit-scrollbar {
  display: none;
}

.hide-it {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* --------------- */

body,
html {
  overscroll-behavior: none;
  box-sizing: border-box !important;
  font-size: 12px;
}

#home_hero_be_discovered_btn svg {
  margin-right: 0.416vw;
  width: 1.85vh;
  height: 1.85vh;
}

#home_hero_discover_talent_btn svg {
  margin-right: 0.416vw;
  width: 1.85vh;
  height: 1.85vh;
}

#navbar_become_member_btn svg {
  margin-right: 0.56vw;
  height: 2.22vh;
  width: 2.22vh;
}

#our_mission_btn svg {
  margin-right: 0.56vw;
  height: 2.22vh;
  width: 2.22vh;
}

/* .splide__arrows {
	visibility: hidden;
}

.splide__pagination {
	visibility: hidden;
} */
/* 
.hero .splide__arrows,
.hero .splide__arrows--ltr {
	z-index: 10;
	position: absolute;
	width: 12.93vw;
	height: 22.4vh;
	background: rgba(8, 32, 50, 0.65);
	right: 0;
	bottom: 0;
	border-radius: 32px 0 0 0;
}

.hero .splide__arrows .orange_bar {
	width: 50px;
	height: 5px;
	background: rgb(255, 136, 0);
	border-radius: 20px;
	float: right;
}

.hero .slider_controls {
	display: flex;
	justify-content: space-between;
	padding-top: 40px;
	width: 100%;
	float: right;
}
.hero .splide__arrow {
}
.hero .splide__arrow svg {
	fill: #fff;
}
.hero .slider_city {
	margin-top: 10px;
	font-size: 20px;
	text-align: end;
	color: #fff;
}
.hero .slider_location {
	font-size: 24px;
	font-weight: 600;
	text-align: end;
	color: #fff;
}

.hero .splide__arrow--prev {
	background: transparent;
	position: relative !important;
	height: 30px;
	width: 30px;
	color: #fff;
}
.hero .splide__arrow--prev svg {
	transform: rotateZ(0deg) !important;
	font-size: 30px;
}
.hero .splide__arrow--next {
	background: transparent;
	position: relative !important;
	height: 30px;
	width: 30px;
	padding: 0 !important;
	margin: 0 !important;

	right: 0 !important;
}
.hero .splide__arrow--next svg {
	font-size: 30px;
}

@media screen and (max-width: 992px) {
	.hero .splide__arrows,
	.hero .splide__arrows--ltr {
		display: none;
	}
} */
.hero .splide__arrows,
.hero .splide__arrows--ltr {
  display: none;
}

.proposal-modal-input {
  margin-top: 1rem;

  margin-bottom: 1rem;
}

.d-hide {
  display: none !important;
}

.m-hide {
  display: block !important;
}

#talent_container button.splide__arrow.splide__arrow--prev {
  background: "orange" !important;
  height: 3rem !important;
  width: 3rem !important;
  top: 50% !important;
  left: 0 !important;
  transform: translateX(-50%) translateY(-50%);
}

#talent_container button.splide__arrow.splide__arrow--next {
  background: "orange" !important;
  height: 3rem !important;
  width: 3rem !important;
  top: 50% !important;
  right: 0 !important;
  transform: translateX(50%) translateY(-50%);
}
#talent_container button.splide__arrow.splide__arrow--next svg {
  fill: white !important;
}

#talent_container button.splide__arrow.splide__arrow--prev svg {
  fill: white !important;
}

#talent_container .splide__arrow {
  background: #f6540e !important;
  opacity: 1 !important;
  color: white;
}

#profile_slides button.splide__arrow.splide__arrow--prev,
#profile_slides button.splide__arrow.splide__arrow--next {
  display: none !important;
}

.ql-toolbar.ql-snow {
  border-radius: 1.2rem 1.2rem 0 0 !important;
  border-color: #e2e8f0 !important;
  border-width: 1px !important;
}

.ql-editor.ql-blank {
  border: none !important;
}

.ql-container.ql-snow {
  border-radius: 0 0 1.2rem 1.2rem !important;
  border-color: #e2e8f0 !important;
  border-width: 1px !important;
  min-height: 200px;
}

@media only screen and (max-width: 600px) {
  #talent_container button.splide__arrow.splide__arrow--prev {
    height: 4rem !important;
    width: 4rem !important;
  }

  #talent_container button.splide__arrow.splide__arrow--next {
    height: 4rem !important;
    width: 4rem !important;
  }
  .w100 {
    width: 100% !important;
  }

  .h100 {
    height: 100% !important;
  }

  .m-10 {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .p-10 {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .modal-or-margin {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .modal-font-1 {
    font-size: 2.5rem !important;
  }

  .modal-font-2 {
    font-size: 2rem !important;
  }

  .hero-box {
    padding-left: 12%;
    padding-right: 12%;
  }

  .hero-image-radius {
    border-radius: 30rem !important;
  }

  .hero-font-class1 {
    font-size: 2rem !important;
    text-align: center !important;
  }

  .hero-font-class2 {
    font-size: 1.2rem !important;
  }

  .hero-buttons {
    display: contents !important;
  }

  .hero-buttons {
    display: contents !important;
  }

  .hero {
    padding-left: 5%;
    padding-right: 5%;
  }

  .lyrics-heading-1 {
    font-size: 2rem !important;
  }

  .lyrics-heading-2 {
    font-size: 1.2rem !important;
  }

  .genre-category-width {
    width: 100% !important;
    margin-top: 1%;
    margin-bottom: 1%;
    /* margin-left: 2%; */
    font-size: 1rem !important;
  }

  .genre-category-icons {
    height: 2rem !important;
    width: 2rem !important;
  }

  .genre-category-icons2 {
    height: 1rem !important;
    width: 1rem !important;
  }

  .job-h1 {
    margin-left: 2rem;
    font-size: 3rem !important;
  }

  .job-sidebar {
    display: none;
  }

  .jobCard {
    width: 100% !important;
  }

  .jobCard-avatar {
    width: 5rem !important;
    height: 5rem !important;
  }

  .jobcard-genre {
    width: 48%;
  }

  .single-Lyrics-page-avatar {
    width: 8rem !important;
    height: 8rem !important;
  }

  .single-Lyrics-page-button {
    width: 50vw !important;
  }

  .single-Lyrics-page-heading1 {
    font-size: 1rem !important;
  }

  .profile-image {
    border-radius: 8% !important;
  }

  .profile-rating-icons {
    height: 1rem !important;
    width: 1rem !important;
  }

  .d-hide {
    display: block !important;
  }

  .m-hide {
    display: none !important;
  }

  .review-card {
    width: 80vw !important;
  }

  .about-us-width {
    font-size: 1.2rem !important;
    width: calc(100% - 14vw) !important;
  }

  .about-us-margin {
    margin-top: 40px !important;
    margin-bottom: 50px !important;
  }

  .dashboard-box {
    height: 8vh !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .chatbox-messages-width {
    width: 45% !important;
    border-color: transparent !important;
  }

  .talent {
    font-size: 3rem !important;
  }

  .talent-arrow-icons {
    position: absolute !important;
    bottom: 15.96vh !important;
  }
}
