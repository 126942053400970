.icon {
  height: 1.66vw;
  width: 1.66vw;
}

@media only screen and (max-width: 768px) {
  .icon {
    height: 1.8rem;
    width: 1.8rem;
  }
}
